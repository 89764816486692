import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import scanAct from '../views/scanAct.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/scanAct',
		name: 'scanAct',
		component: scanAct
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior: () => {
		console.log("router------------", router);
	  history.pushState(null, null, document.URL)
	}
})
		
export default router
