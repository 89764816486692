import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		userWechatCode: "",
		userOpenId: "",
		frame1: '',
		frame2: '',
		frame3: '',
		frame4: '',
		frame5: '',
		callValue: 0,
		usedCallValue: 0,
		frame5Stamped: false,
		codeTotal: 0,
		totalCallValue: 0
	},
	mutations: {
		updateUserWechatCode(state, code) {
			state.userWechatCode = code;
		},
		updateUserOpenId(state, openId) {
			state.userOpenId = openId;
		},
		updateUserframe1(state, score) {
			state.frame1 = score;
		},
		updateUserframe2(state, score) {
			state.frame2 = score;
		},
		updateUserframe3(state, score) {
			state.frame3 = score;
		},
		updateUserframe4(state, score) {
			state.frame4 = score;
		},
		updateUserframe5(state, score) {
			state.frame5 = score;
		},
		updateUserUsedCallValue(state, score) {
			state.usedCallValue = score;
		},
		updateUserFrame5Stamped(state, stamped) {
			state.frame5Stamped = stamped;
		},
		updateUserTotalCallValue(state, score) {
			state.totalCallValue = score;
		},
		updateUserCodeTotal(state, score) {
			state.codeTotal = score;
		},
	},
	actions: {},
	modules: {}
})
