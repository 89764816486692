/**
 * getUrlKey 截取地址栏参数
 * @param value
 * @returns {String}
 */
import axios from 'axios'

let base_url = 'https://vertify.maopaisz.com/hisenseAct';
// let base_url = 'http://192.168.1.109:3333/hisenseAct';
function getUrlKey(name, string) {
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
        string
      ) || [, ''])[1].replace(/\+/g, '%20')
    ) || null
  );
}

function redirectToAuthPage(name) {
	console.log("[wechatutil-----------redirectToAuthPage]");
	const local = window.location.href;
	// window.location.href =
	// 	'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3b0be6bf67633130&redirect_uri=' +
	// 	encodeURIComponent(local.split('#')[0]) +
	// 	'&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect';
		
		window.location.replace('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3b0be6bf67633130&redirect_uri=' +
		encodeURIComponent(local.split('#')[0]) +
		'&response_type=code&scope=snsapi_base&state=1#wechat_redirect');
}

async function getOpenId(code) {
	console.log("[wechatutil-----------getOpenId]");
	let that = this;
	return await axios
		.post(base_url + '/getOpenId', {
			// .post('http://192.168.1.109:3333/hisenseAct/getOpenId', {
			code,
		})
		.then(function(response) {
			if (response.data.data.openid) {
				return response.data.data.openid;
			} else {
				that.$wechatUtils.redirectToAuthPage();
			}
		})
		.catch(function(error) {
			console.log(error);
		});
}

async function getConfigJson(){
	// console.log("url...........", location.href.split('#')[0]);
	// console.log("encode url...........", encodeURIComponent(location.href.split('#')[0]));
	// console.log("getConfigJson");
	let that = this;
	return await axios
			.post(base_url + '/getConfigJson', {
			// .post('http://192.168.1.109:3333/hisenseAct/getConfigJson', {
			url:encodeURIComponent(location.href.split('#')[0])
		})
		.then(function(response) {
			// console.log("getConfigJson.....response....", response.data.data);
			if (response.data.data){
				return response.data.data;
			}
		})
		.catch(function(error) {
			console.log(error);
		});
}

async function initWxConfig(code){
	// console.log("initWxConfig");
	let json = await getConfigJson(code);
	// console.log("appId", json.appId);
	// console.log("sign", json.signature);
	// console.log("onceStr", json.nonceStr);
	// console.log("timeStamp", json.timestamp);
	wx.config({
	    debug: false,
	    appId: json.appId,
	    timestamp: json.timestamp,
	    nonceStr: json.nonceStr,
	    signature: json.signature,
	    jsApiList: ['scanQRCode', 
			'checkJsApi',
            'onMenuShareTimeline',
            'onMenuShareAppMessage',
            'onMenuShareQQ',
            'onMenuShareWeibo']
	});
	
	wx.ready(function () {
	   console.log("wx.ready()");
	   wx.onMenuShareTimeline({
	       title: "圣诞NICE TO C YOU",
	       // link: location.href.split('#')[0],
		   link: "https://hisense.act.maopaisz.com",
	       imgUrl: "https://696e-inner-mqvc8-1302115097.tcb.qcloud.la/xiaoc.png?sign=7444203dda39fc86ed8d95a55f770cf0&t=1606537826",
	       success: function () {}
	   });
	   			
	   wx.onMenuShareAppMessage({
	       title: "圣诞NICE TO C YOU",
	       desc: "4大互动体验赢价值20万惊喜圣诞礼物",
	       link: "https://hisense.act.maopaisz.com",
	       imgUrl: "https://696e-inner-mqvc8-1302115097.tcb.qcloud.la/xiaoc.png?sign=7444203dda39fc86ed8d95a55f770cf0&t=1606537826",
	   			
	       success: function () {}
	   });
	});
	
	wx.error(function(res){
		console.log("wx.error()", res);
	});
}

//扫描二维码后,调用此函数更新分数，每次+2
async function updateScanCodeScore(openId, codeId){
	// console.log("updateScanCodeScore----------openId-----------", openId);
	// console.log("updateScanCodeScore----------codeId-----------", codeId); 
}

async function scQrCode(openId){
	
}

//拉取用户数据库信息 
async function getUserInfo(openId){
	let that = this;
	return await axios
			.post(base_url + '/getUserInfo', {
			// .post('http://192.168.1.109:3333/hisenseAct/getUserInfo', {
			openId
		})
		.then(function(response) {
			console.log("getUserInfo.....response....", response.data.data);
			return response.data.data;
			
		})
		.catch(function(error) {
			console.log(error);
		});
}

//将openId以及章Id传到后台，后台处理逻辑
async function updateWithStamp(openId, stampId){
	let that = this;
	return await axios
			.post(base_url + '/updateWithStamp', {
			// .post('http://192.168.1.109:3333/hisenseAct/updateWithStamp', {
			openId, 
			stampId
		})
		.then(function(response) {
			console.log("updateWithStamp.....response....", response.data.data);
			return response.data.data;
			
		})
		.catch(function(error) {
			console.log(error);
		});
}

async function getWechatInfo(){
	
}

export default {
	redirectToAuthPage,
	getOpenId,
	initWxConfig,
	scQrCode,
	getUserInfo,
	updateWithStamp,
	getUrlKey
};
