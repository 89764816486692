<template>
	<div class="home">
		<img class="main_bg" id="main_bg_1" src="../assets/main_bg_1.png" alt="" />
		<img class="main_bg" id="main_bg_6" src="../assets/stamp_bg.png" alt="" />
		<img class="main_bg" id="main_bg_2" src="../assets/main_bg_2.png" alt="" />
		<img class="main_bg" id="main_bg_3" src="../assets/main_bg_3.png" alt="" />
		<img class="main_bg" id="main_bg_4" src="../assets/main_bg_4.png" alt="" />
		<img class="main_bg" id="main_bg_5" src="../assets/main_bg_5.png" alt="" />
		<img src="../assets/logo.png" alt="" class="logo" />
		<div class="stamp_logic_part">
			<div class="stamp_logic_part_top_desc">*日常打卡与限时翻倍不可重复参与，每人限1次</div>
			<div class="last_text_desc">寻小C好友助力打CALL</div>
			<div class="middle_flex">
				<div id="first" class="each_part">
					<img v-if="$store.state.frame1 == 0" class="no_png" src="../assets/no.png" alt="" />
					<img v-else class="yes_png" src="../assets/yes.png" alt="" />
					<img v-if="$store.state.frame2 != 0" class="nomore" src="../assets/nomore.png" alt="" />
					<div class="text_desc">小C咔嚓一刻</div>
					<div class="frame_1_text">日常打卡</div>
					<img v-if="$store.state.frame1 == 0" src="../assets/dimend_no.png" alt="" class="dimend_no_top" />
					<img v-else src="../assets/dimend_yes.png" alt="" class="dimend_yes_top" />
					<div class="score_text_top">10分</div>
				</div>
				<div id="second" class="each_part">
					<img v-if="$store.state.frame2 == 0" class="no_png" src="../assets/no.png" alt="" />
					<img v-else class="yes_png" src="../assets/yes.png" alt="" />
					<img v-if="$store.state.frame1 != 0" class="nomore" src="../assets/nomore.png" alt="" />
					<div class="text_desc">小C咔嚓一刻</div>
					<div class="frame_1_text">限时翻倍</div>
					<img v-if="$store.state.frame2 == 0" src="../assets/dimend_no.png" alt="" class="dimend_no_top" />
					<img v-else src="../assets/dimend_yes.png" alt="" class="dimend_yes_top" />
					<div class="score_text_top">20分</div>
				</div>
				<div id="third" class="each_part">
					<img v-if="$store.state.frame3 == 0" class="no_png" src="../assets/no.png" alt="" />
					<img v-else class="yes_png" src="../assets/yes.png" alt="" />
					<div class="text_desc">圣诞亮灯</div>
					<div class="frame_1_text"></div>
					<img v-if="$store.state.frame3 == 0" src="../assets/dimend_no.png" alt="" class="dimend_no_top" />
					<img v-else src="../assets/dimend_yes.png" alt="" class="dimend_yes_top" />
					<div class="score_text_top_three">40分</div>
				</div>
				<div id="forth" class="each_part">
					<img v-if="$store.state.frame4 == 0" class="no_png" src="../assets/no.png" alt="" />
					<img v-else class="yes_png" src="../assets/yes.png" alt="" />
					<div class="text_desc">C NINE公演</div>
					<img v-if="$store.state.frame4 == 0" src="../assets/dimend_no.png" alt="" class="dimend_no" />
					<img v-else src="../assets/dimend_yes.png" alt="" class="dimend_yes" />
					<div class="score_text">30分</div>
				</div>
				<div id="fifth" class="each_part">
					<img v-if="$store.state.frame5Stamped == false" class="no_png" src="../assets/no.png" alt="" />
					<img v-else class="yes_png" src="../assets/yes.png" alt="" />
					<!-- <div class="last_text_desc">寻小C好友助力打CALL</div> -->
					<div class="sisth_progress_bg"></div>
					<div :style="scan_progress_style" class="sisth_progress"></div>
					<!-- <div class="sisth_progress_value">{{ $store.state.codeTotal + '/60' }}</div> -->
					<div class="sisth_progress_value" id="code_total">--/60</div>
				</div>
				<div @click="scanCode" id="sisth" class="each_part"><img class="scan_png" src="../assets/scan_btn.png" alt="" /></div>
			</div>
			<div class="call_value_static_text">打CALL值</div>
			<!-- <div class="current_call_value_total">{{ $store.state.totalCallValue }}</div> -->
			<div id="totalCallValue" class="current_call_value_total">--</div>
			<div class="call_value_total">/150</div>
			<div class="call_value_bg"></div>
			<div :style="total_call_progress_style" class="call_value_progress"></div>
			<div class="finished_call_value_static_text">已消耗打CALL值</div>
			<!-- <div class="current_finished_call_value_total">{{ $store.state.usedCallValue }}</div> -->
			<div id="usedCallValue" class="current_finished_call_value_total">--</div>
			<div class="finished_call_value_total">/150</div>
			<div class="finished_call_value_bg"></div>  
			<div :style="used_total_call_progress_style" class="finished_call_value_progress"></div>
			<!-- <img class="exchange_btn" src="../assets/exchange_btn.png" alt="" /> -->
			<!-- <img src="../assets/hidden.png" alt="" @click="onRevertClicked" class="hidden_btn" /> -->
			<div @click="onRevertClicked" class="hidden_btn"><img class="hidden_btn_img" src="../assets/hidden.png" alt="" /></div>

			<img src="../assets/arrow.png" alt="" class="arrow" />
		</div>
		<div class="dialog" :class="{ showAlert: !showAlert }"></div>
		<div class="dialog_bg" :class="{ showAlert: !showAlert }">
			<img src="../assets/littlec.png" alt="" class="little_c" />
			<div class="alert_text">{{ alertText }}</div>
			<div class="line"></div>
			<div class="confirm" @click="onConfirmClicked">我知道了</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Home',
	components: {},
	mounted: function() {
		console.log('this.mounted.');
		//获取微信用户信息
		this.getUserWechatInfo();
		this.$wechatUtils.initWxConfig();
		this.showStampPage();
	},
	data() {
		return {
			wechatCode: '',
			openId: '',
			lastCodeValue: 0,
			lastCallValue: 0,
			lastUsedCallValue: 0,
			alertText: '~~',
			showAlert: false,
			scan_progress_style: {
				width: '0rem'
			},
			total_call_progress_style: {
				width: '0rem'
			},
			used_total_call_progress_style: {
				width: '0rem'
			},
			codeTotal: '',
			usedCallValue: '',
			totalCallValue: '',
			isAllowedClicked: true
		};
	},
	methods: {
		stopScroll() {
			console.log("stop scroll");
			//禁止页面滑动
			document.body.style.overflow = 'hidden';
			document.addEventListener(
				'touchmove',
				e => {
					e.preventDefault();
				},
				false
			);
		},
		enableScroll() {
			console.log("enable scroll");
			// 解绑禁止页面滚动事件
			document.body.style.overflow = '';
			document.removeEventListener(
				'touchmove',
				e => {
					e.preventDefault();
				},
				false
			);
		},
		onConfirmClicked() {
			this.showAlert = false;
			this.enableScroll();
			// alert('点击了确定');
		},
		//执行扫码回滚操作
		onRevertClicked() {
			console.log('scan code--------clicked');
			let that = this;
			wx.scanQRCode({
				needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
				scanType: ['qrCode'], // 可以指定扫二维码还是一维码，默认二者都有
				success: function(res) {
					var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
					console.log('res----------', res);
					let codeId = that.$wechatUtils.getUrlKey('codeId', result);
					let stampId = that.$wechatUtils.getUrlKey('stampId', result);
					console.log('codeId---------', codeId);
					if (codeId && codeId == 8888) {
						that.$axios
							.post('https://vertify.maopaisz.com/hisenseAct/revertAction', {
								// .post('http://192.168.1.109:3333/hisenseAct/revertAction', {
								openId: that.$store.state.userOpenId,
								codeId
							})
							.then(function(response) {
								console.log('getUserInfo.....response....', response.data.data);
								that.asycInfoWithDataReturn(response.data.data);
								if (!response.data.data.errCode) {
									esp.showSuccessAnim();
								}
								return response.data.data;
							})
							.catch(function(error) {
								console.log(error);
							});
					}

					if (stampId) {
						that.$axios
							.post('https://vertify.maopaisz.com/hisenseAct/updateWithStamp', {
								// .post('http://192.168.1.109:3333/hisenseAct/revertAction', {
								openId: that.$store.state.userOpenId,
								stampId
							})
							.then(function(response) {
								console.log('getUserInfo.....response....', response.data.data);
								that.asycInfoWithDataReturn(response.data.data);
								if (!response.data.data.errCode) {
									esp.showSuccessAnim();
								}
								return response.data.data;
							})
							.catch(function(error) {
								console.log(error);
							});
					}
				}
			});
		},
		updateScanCodePregressBar() {
			let ratio = (this.$store.state.codeTotal / 60) * 0.6;
			this.codeTotal = this.$store.state.codeTotal;
			document.getElementById('code_total').innerHTML = this.$store.state.codeTotal+"/60";
			console.log('updateScanCodePregressBar--------ratio-------', ratio);
			if (this.$store.state.codeTotal != 0 && this.$store.state.codeTotal < 8) {
				this.scan_progress_style.width = '0.08rem';
			} else {
				this.scan_progress_style.width = ratio.toString() + 'rem';
			}
		},
		updateTotalCallValueProgressBar() {
			let ratio = (this.$store.state.totalCallValue / 150) * 5.526;
			this.totalCallValue = this.$store.state.totalCallValue;
			document.getElementById('totalCallValue').innerHTML = this.$store.state.totalCallValue;
			console.log('updateTotalCallValueProgressBar, ratio');
			this.total_call_progress_style.width = ratio.toString() + 'rem';
		},
		updateUsedTotalCallValueProgressBar() {
			let ratio = (this.$store.state.usedCallValue / 150) * 5.526;
			this.usedCallValue = this.$store.state.usedCallValue;
			document.getElementById('usedCallValue').innerHTML = this.$store.state.usedCallValue;
			console.log('updateUsedTotalCallValueProgressBar, ratio');
			this.used_total_call_progress_style.width = ratio.toString() + 'rem';
		},
		asycInfoWithDataReturn(data) {
			let that = this;
			this.$store.commit('updateUserframe1', data.frame1);
			this.$store.commit('updateUserframe2', data.frame2);
			this.$store.commit('updateUserframe3', data.frame3);
			this.$store.commit('updateUserframe4', data.frame4);
			this.$store.commit('updateUserframe5', data.frame5);
			this.$store.commit('updateUserUsedCallValue', data.usedCallValue);
			this.$store.commit('updateUserFrame5Stamped', data.frame5Stamped);
			this.$store.commit('updateUserTotalCallValue', data.totalCallValue);
			this.updateTotalCallValueProgressBar();
			this.updateUsedTotalCallValueProgressBar();
			console.log('$store.state.totalCallValue', this.$store.state.totalCallValue);
			// if (that.frame5Stamped == true) {
			// 	that.codeTotal = 0;
			// 	this.$store.commit('updateUserCodeTotal', 0);
			// } else {
			that.codeTotal = data.codeTotal;
			this.$store.commit('updateUserCodeTotal', data.codeTotal);
			// }
			this.updateScanCodePregressBar();
			if (data.errCode) {
				// esp.closeStampCertifcationPage();
				if (data.errCode == '0001') {
					this.alertText = "'日常打卡'与'限时翻倍'二选一哦~";
					// alert('第一个和第二个不可以同时积分.');
				} else if (data.errCode == '0002') {
					this.alertText = "'圣诞亮灯'已经加过分了哦~";
				} else if (data.errCode == '0003') {
					this.alertText = "'C NINE公演'已经加过分了哦~";
				} else if (data.errCode == '0004') {
					this.alertText = '积分少于30,不可以兑换哦~';
				} else if (data.errCode == '0005') {
					this.alertText = '积分少于60,不可以兑换哦~';
				} else if (data.errCode == '0006') {
					this.alertText = '积分少于90,不可以兑换哦~';
				} else if (data.errCode == '0007') {
					this.alertText = '不可以重复扫码哦~';
				} else if (data.errCode == '0008') {
					this.alertText = '这枚章已经盖过了哦~';
				} else if (data.errCode == '0010') {
					this.alertText = '每次只能往回撤销一步哦~';
				}
				this.showAlert = true;
				this.stopScroll();
			}
		},
		async scanCode() {
			if (this.isAllowedClicked == true) {
				if (this.$store.state.frame5Stamped == true) {
					this.alertText = '盖章之后不可再次参与扫码了哦~';
					this.showAlert = true;
					this.stopScroll();
					return;
				}
				this.$router.replace('/scanAct');
			}
		},
		showStampPage() {
			// this.success_anim = document.querySelector('#stamp-success-anim');
			// this.success_anim.style.opacity = "0";
			let that = this;
			esp.showStampCertifcationPage({
				regionCode: esp.REGION_CODE_TYPE.CHINA,
				languageCode: esp.LANGUAGE_CODE_TYPE.CHINESE,
				licenseId: 'L45435790033', //联系管理员获取
				merchantCode: 'A4582577322' //联系管理员获取
			});

			let vertifySuccess = data => {
				console.log('vertifySuccess------data', data);
				console.log('data.result.stamp--------------', data.result.stamp);
				if (data.result && data.result.stamp) {
					that.$wechatUtils.updateWithStamp(this.$store.state.userOpenId, data.result.stamp).then(function(data) {
						that.asycInfoWithDataReturn(data);
						if (!data.errCode) {
							esp.showSuccessAnim();
						}
					});
				}
			};
			esp.certSuccess(vertifySuccess.bind(this));

			let clickDisableCb = data => {
				that.isAllowedClicked = false;
				that.stopScroll();
				setTimeout(function() {
					//进入timeout
					that.isAllowedClicked = true;
					that.enableScroll();
				}, 800);
				console.log('getClickDisablecb vue');
			};

			esp.setClickDisable(clickDisableCb.bind(this));

			//设置失败回调
			esp.certError(function cb(data) {
				alert('请将印章水平盖在屏幕上维持一秒');
			});
		},
		async onTestClicked() {
			this.$wechatUtils.scQrCode(this.$store.state.userOpenId);
			// this.$router.push({path:'/scanAct'})
		},
		async getUserWechatInfo() {
			let that = this;
			this.wechatCode = this.$utils.getUrlKey('code');
			console.log('[getUserWechatInfo------------wechatcode:]', this.wechatCode);
			if (this.wechatCode) {
				this.openId = await this.$wechatUtils.getOpenId(this.wechatCode);
				this.$store.commit('updateUserOpenId', this.openId);
				this.asynUserInfo();
			} else {
				this.$wechatUtils.redirectToAuthPage();
			}
		},
		//同步用户信息
		async asynUserInfo() {
			let that = this;
			this.$wechatUtils.getUserInfo(this.openId).then(function(data) {
				that.asycInfoWithDataReturn(data);
			});
		}
	},
	created() {}
};
</script>

<style scoped>
.main_bg {
	width: 7.5rem;
}

#main_bg_6 {
	margin-top: -0.28rem;
}

#main_bg_2 {
	/* margin-top: -0.245rem; */
	margin-top: -0.28rem;
}

#main_bg_3 {
	margin-top: -0.28rem;
}

#main_bg_4 {
	margin-top: -0.28rem;
}

#main_bg_5 {
	margin-top: -0.28rem;
}

.logo {
	position: absolute;
	width: 1.67rem;
	height: 0.62rem;
	top: 0.48rem;
	left: 0.33rem;
}

.top_light {
	position: absolute;
	width: 7.5rem;
	height: 6.25rem;
	top: -0.35rem;
	left: 0rem;
}
.stamp_bg {
	position: absolute;
	width: 7.5rem;
	height: 12.16rem;
	left: 0rem;
	top: 5.02rem;
}

.stamp_logic_part {
	position: absolute;
	width: 7.5rem;
	height: 12.16rem;
	left: 0rem;
	top: 5.02rem;
	/* background-color: rgba(1, 1, 1, 0.1); */
}

.stamp_logic_part_top_desc {
	width: 5.5rem;
	height: 0.2rem;
	/* font-family: AlibabaPuHuiTiR; */
	font-weight: bold;
	color: #aa99cd;
	line-height: 0px;
	font-size: 0.14rem;
	position: absolute;
	top: 1rem;
	left: 0.9rem;
}

.middle_flex {
	position: absolute;
	/* background-color: rgba(1, 1, 1, 0.2); */
	width: 6rem;
	left: 0.75rem;
	top: 1.4rem;
	height: 6.4rem;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	/* align-items: center; */
}

.each_part {
	width: 2rem;
	height: 1.36rem;
}

.no_png {
	width: 1.36rem;
	height: 1.36rem;
	margin-left: 0.32rem;
}

.yes_png {
	width: 1.36rem;
	height: 1.36rem;
	margin-left: 0.32rem;
}

.scan_png {
	width: 1.36rem;
	height: 1.36rem;
	/* margin-top: -0.1rem;
	margin-left: 0.2rem; */
	margin-left: 0.32rem;
}

.text_desc {
	text-align: center;
	/* margin-top: -0.2rem; */
	/* font-family: AlibabaPuHuiTiM; */
	font-weight: bold;
	font-size: 0.3rem;
	color: #675096;
	line-height: 0px;
}

.frame_1_text {
	font-size: 0.14rem;
	font-weight: bold;
	color: #675096;
	line-height: 0px;
	text-align: center;
	margin-top: 0.4rem;
}
/* .last_text_desc {
	font-size: 0.3rem;
	font-weight: bold;
	color: #675096;
	line-height: 0px;
	margin-left: 1.1rem;
	position: absolute;
} */
.last_text_desc {
	font-size: 0.3rem;
	/* margin-left: 1.5rem; */
	font-weight: bold;
	color: #675096;
	line-height: 0px;
	margin-left: 3.2rem;
	margin-top: 6.24rem;
	position: absolute;
}

.sisth_progress_bg {
	width: 0.6rem;
	height: 0.17rem;
	background: linear-gradient(90deg, #c4a5c1 0%, #cdd4da 100%);
	opacity: 0.81;
	border-radius: 9px;
	margin-top: 0.33rem;
	position: absolute;
	/* margin-left: 0.4rem; */
	margin-left: 1.4rem;
}
.sisth_progress {
	width: 0.22rem;
	height: 0.17rem;
	background: linear-gradient(0deg, #ff92f3 0%, #bf68ff 100%); 
	border-radius: 9px;
	margin-top: 0.33rem;
	opacity: 0.81;
	/* margin-left: 0.4rem; */
	margin-left: 1.4rem;
}

.sisth_progress_value {
	font-size: 0.24rem;
	font-family: Alibaba PuHuiTi;
	font-weight: bolder;
	color: #675096;
	line-height: 0px;
	margin-top: -0.1rem;
	margin-left: 2.1rem;
}

.dimend_no,
.dimend_yes {
	width: 0.28rem;
	height: 0.22rem;
	position: absolute;
	margin-top: 0.29rem;
	margin-left: 0.6rem;
}
.dimend_yes_top,
.dimend_no_top {
	width: 0.28rem;
	height: 0.22rem;
	position: absolute;
	margin-top: 0.3rem;
	margin-left: 0.6rem;
}
.score_text {
	font-size: 0.24rem;
	/* font-family: AlibabaPuHuiTiB; */
	font-weight: bold;
	color: #675096;
	line-height: 0px;
	margin-top: 0.4rem;
	margin-left: 0.95rem;
}

.score_text_top {
	font-size: 0.24rem;
	/* font-family: AlibabaPuHuiTiB; */
	font-weight: bold;
	color: #675096;
	line-height: 0px;
	margin-top: 0.42rem;
	margin-left: 0.95rem;
}

.score_text_top_three {
	font-size: 0.24rem;
	/* font-family: AlibabaPuHuiTiB; */
	font-weight: bold;
	color: #675096;
	line-height: 0px;
	margin-top: 0.82rem;
	margin-left: 0.95rem;
}

.call_value_static_text {
	font-size: 0.26rem;
	/* font-family: AlibabaPuHuiTiM; */
	font-weight: bold;
	color: #675096;
	line-height: 0px;
	margin-top: 7.5rem;
	margin-left: 1rem;
}

.call_value_total {
	font-size: 0.26rem;
	font-weight: bolder;
	color: #675096;
	line-height: 0px;
	margin-left: 5.85rem;
}
.current_call_value_total {
	font-size: 0.26rem;
	font-weight: bolder;
	color: #d855fd;
	line-height: 0px;
	right: 1.65rem;
	position: absolute;
}
.current_finished_call_value_total {
	font-size: 0.26rem;
	font-weight: bolder;
	color: #00a1e8;
	line-height: 0px;
	right: 1.65rem;
	position: absolute;
}
.finished_call_value_total {
	font-size: 0.26rem;
	font-weight: bolder;
	color: #675096;
	line-height: 0px;
	margin-left: 5.85rem;
}
.call_value_bg {
	width: 5.52rem;
	height: 0.26rem;
	background: linear-gradient(90deg, #c4a5c1 0%, #cdd4da 100%);
	opacity: 0.81;
	border-radius: 13px;
	position: absolute;
	margin-top: 0.25rem;
	margin-left: 0.99rem;
}

.call_value_progress {
	width: 3.52rem;
	height: 0.26rem;
	background: linear-gradient(0deg, #ff92f3 0%, #bf68ff 100%);
	opacity: 0.81;
	border-radius: 13px;
	position: absolute;
	margin-top: 0.25rem;
	margin-left: 0.99rem;
}

.finished_call_value_progress {
	width: 3.52rem;
	height: 0.26rem;
	background: linear-gradient(0deg, #27b4fd 0%, #67ebff 100%);
	opacity: 0.81;
	border-radius: 13px;
	position: absolute;
	margin-top: 0.25rem;
	margin-left: 0.99rem;
}

.finished_call_value_bg {
	width: 5.52rem;
	height: 0.26rem;
	background: linear-gradient(90deg, #c4a5c1 0%, #cdd4da 100%);
	opacity: 0.81;
	border-radius: 13px;
	position: absolute;
	margin-top: 0.25rem;
	margin-left: 0.99rem;
}

.finished_call_value_static_text {
	font-size: 0.26rem;
	/* font-family: AlibabaPuHuiTiM; */
	font-weight: bold;
	color: #675096;
	line-height: 0px;
	margin-top: 1.1rem;
	margin-left: 1rem;
}
.nomore {
	position: absolute;
	width: 1.39rem;
	height: 0.78rem;
	margin-top: 0.3rem;
	margin-left: -1.35rem;
}
.hidden_btn {
	width: 0.62rem;
	height: 0.3rem;
	position: absolute;
	top: -0.75rem;
	right: 0.3rem;
}

.hidden_btn_img {
	width: 0.62rem;
	height: 0.3rem;
	position: absolute;
	top: 0rem;
	right: 0rem;
}

.dialog {
	display: flex;
	margin: 0px;
	padding: 0px;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: #000000;
	opacity: 0.8;
	align-items: center;
	justify-content: center;
}

.dialog_bg {
	width: 5.28rem;
	height: 5.53rem;
	background: #ffffff;
	box-shadow: 0px 2px 21px 3px rgba(179, 58, 190, 0.19);
	border-radius: 28px;
	position: fixed;
	left: 1.11rem;
	top: 30%;
	/* vertical-align: center; */
}

.little_c {
	width: 2.28rem;
	height: 2.28rem;
	margin-left: 1.5rem;
	margin-top: 0.54rem;
}

.alert_text {
	font-size: 0.28rem;
	/* font-family: Alibaba PuHuiTi; */
	font-weight: bold;
	color: #675096;
	/* line-height: 34px; */
	text-align: center;
	margin-top: 0.16rem;
}
.line {
	width: 100%;
	height: 0.02rem;
	background: #f0f0f0;
	margin-top: 0.7rem;
}
.confirm {
	font-size: 0.34rem;
	/* font-family: Alibaba PuHuiTi; */
	font-weight: blod;
	color: #0078ff;
	line-height: 34px;
	text-align: center;
	margin-top: 0.2rem;
}
.showAlert {
	display: none;
}
.arrow {
	position: absolute;
	width: 0.49rem;
	height: 0.39rem;
	top: 5.1rem;
	right: 2.5rem;
}
</style>
