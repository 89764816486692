<template>
	<div class="scanact">
		<img src="../assets/second_bg.png" alt="" class="bg" />
		<!-- <img src="../assets/second_return.png" alt="" class="return_btn" @click="onReturnClicked" /> -->
		<div @click="onReturnClicked" class="return_btn"><img class="return_img" src="../assets/second_return.png" alt="" /></div>
		<!-- <div @click="scanCode" id="sisth" class="each_part"><img class="scan_png" src="../assets/scan_btn.png" alt="" /></div> -->
		<div class="sisth_progress_bg"></div>
		<div :style="scan_progress_style" class="sisth_progress"></div>
		<div class="sisth_progress_value">{{ $store.state.codeTotal }}</div>
		<div class="sisth_progress_total_value">/ 60</div>
		<div @click="onTestClicked" class="scan_btn"><img class="scan_btn_img" src="../assets/second_scan_btn.png" alt="" /></div>
		<!-- <img src="../assets/scond_ic.png" alt="" class="little_c_scan" /> -->
		<div class="dialog" :class="{ showAlert: !showAlert }"></div>
		<div class="dialog_bg" :class="{ showAlert: !showAlert }">
			<img src="../assets/littlec.png" alt="" class="little_c" />
			<div class="alert_text">{{ alertText }}</div>
			<div class="line"></div>
			<div class="confirm" @click="onConfirmClicked">我知道了</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'scanact',
	components: {},
	mounted: function() {
		console.log('$route.query.codeId...........', this.$route.query.codeId);
	},
	data() {
		return {
			wechatCode: '',
			openId: '',
			showAlert: false,
			alertText: '~~',
			scan_progress_style: {
				width: '0rem'
			}
		};
	},
	methods: {
		stopScroll() {
			console.log("stop scroll");
			//禁止页面滑动
			document.body.style.overflow = 'hidden';
			document.addEventListener(
				'touchmove',
				e => {
					e.preventDefault();
				},
				false
			);
		},
		enableScroll() {
			console.log("enable scroll");
			// 解绑禁止页面滚动事件
			document.body.style.overflow = '';
			document.removeEventListener(
				'touchmove',
				e => {
					e.preventDefault();
				},
				false
			);
		},
		updateScanCodePregressBar() {
			let ratio = (this.$store.state.codeTotal / 60) * 5.4;
			console.log('updateScanCodePregressBar--------ratio-------', ratio);
			// if (this.$store.state.codeTotal != 0 && this.$store.state.codeTotal < 8) {
			// 	this.scan_progress_style.width = '0.08rem';
			// } else {
			this.scan_progress_style.width = ratio.toString() + 'rem';
			// }
		},
		onConfirmClicked() {
			this.showAlert = false;
			this.enableScroll();
			// alert('点击了确定');
		},
		async asycInfoWithDataReturn(data) {
			let that = this;
			this.$store.commit('updateUserframe1', data.frame1);
			this.$store.commit('updateUserframe2', data.frame2);
			this.$store.commit('updateUserframe3', data.frame3);
			this.$store.commit('updateUserframe4', data.frame4);
			this.$store.commit('updateUserframe5', data.frame5);
			this.$store.commit('updateUserUsedCallValue', data.usedCallValue);
			this.$store.commit('updateUserFrame5Stamped', data.frame5Stamped);
			this.$store.commit('updateUserTotalCallValue', data.totalCallValue);
			// this.updateTotalCallValueProgressBar();
			// this.updateUsedTotalCallValueProgressBar();
			console.log('$store.state.totalCallValue', this.$store.state.totalCallValue);
			that.codeTotal = data.codeTotal;
			this.$store.commit('updateUserCodeTotal', data.codeTotal);
			this.updateScanCodePregressBar();
			if (data.errCode) {
				esp.closeStampCertifcationPage();
				if (data.errCode == '0001') {
					this.alertText = "'日常打卡'与'限时翻倍'二选一哦~";
					// alert('第一个和第二个不可以同时积分.');
				} else if (data.errCode == '0002') {
					this.alertText = "'圣诞亮灯'已经加过分了哦~";
				} else if (data.errCode == '0003') {
					this.alertText = "'C NINE公演'已经加过分了哦~";
				} else if (data.errCode == '0004') {
					this.alertText = "积分少于30,不可以兑换哦~";
				} else if (data.errCode == '0005') {
					this.alertText = "积分少于60,不可以兑换哦~";
				} else if (data.errCode == '0006') {
					this.alertText = "积分少于90,不可以兑换哦~";
				} else if (data.errCode == '0007') {
					this.alertText = "不可以重复扫码哦~";
				} else if (data.errCode == '0008') {
					this.alertText = "这枚章已经盖过了哦~";
				} else if (data.errCode == '0010') {
					this.alertText = "每次只能往回撤销一步哦~";
				}
				this.showAlert = true;
				this.stopScroll();
			}
		},
		onTestClicked() {
			console.log("onTestClicked-------");
			let that = this;
			wx.scanQRCode({
				needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
				scanType: ['qrCode'], // 可以指定扫二维码还是一维码，默认二者都有
				success: function(res) {
					var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
					console.log('res----------', res);
					let codeId = that.$wechatUtils.getUrlKey('codeId', result);
					console.log('codeId---------', codeId);
					if (codeId) {
						that.$axios
							.post('https://vertify.maopaisz.com/hisenseAct/scanCode', {
							// .post('http://192.168.1.109:3333/hisenseAct/scanCode', {
								openId: that.$store.state.userOpenId,
								codeId
							})
							.then(function(response) {
								console.log('getUserInfo.....response....', response.data.data);
								that.asycInfoWithDataReturn(response.data.data);
								if (!response.data.data.errCode){
									esp.showSuccessAnim();
								}
								return response.data.data;
							})
							.catch(function(error) {
								console.log(error);
							});
					}
				}
			});
		},
		onReturnClicked() {
			console.log("return_btn");
			this.$router.replace({ path: '/' });
		}
	},
	created() {
		this.$nextTick(() => {
			// 禁用右键
			document.oncontextmenu = new Function('event.returnValue=false');
			// 禁用选择
			document.onselectstart = new Function('event.returnValue=false');
		});
		this.updateScanCodePregressBar();
	}
};
</script>

<style scoped>
.dialog {
	display: flex;
	margin: 0px;
	padding: 0px;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: #000000;
	opacity: 0.8;
	align-items: center;
	justify-content: center;
}

.dialog_bg {
	width: 5.28rem;
	height: 5.53rem;
	background: #ffffff;
	box-shadow: 0px 2px 21px 3px rgba(179, 58, 190, 0.19);
	border-radius: 28px;
	position: fixed;
	left: 1.11rem;
	top: 30%;
	/* vertical-align: center; */
}

.little_c {
	width: 2.28rem;
	height: 2.28rem;
	margin-left: 1.5rem;
	margin-top: 0.54rem;
}

.alert_text {
	font-size: 0.28rem;
	/* font-family: Alibaba PuHuiTi; */
	font-weight: bold;
	color: #675096;
	/* line-height: 34px; */
	text-align: center;
	margin-top: 0.16rem;
}
.line {
	width: 100%;
	height: 0.02rem;
	background: #f0f0f0;
	margin-top: 0.7rem;
}
.confirm {
	font-size: 0.34rem;
	/* font-family: Alibaba PuHuiTi; */
	font-weight: blod;
	color: #0078ff;
	line-height: 34px;
	text-align: center;
	margin-top: 0.2rem;
}
.showAlert {
	display: none;
}

.bg {
	width: 7.5rem;
	height: 14.28rem;
}
.return_btn {
	position: absolute;
	width: 0.88rem;
	height: 0.88rem;
	top: 0.57rem;
	left: 0.38rem;
}

.return_img{
	width: 0.88rem;
	height: 0.88rem;
}
.sisth_progress_bg {
	position: absolute;
	width: 5.4rem;
	height: 0.34rem;
	background: linear-gradient(0deg, #c3c8d6 0%, #e8ebf1 100%);
	opacity: 0.81;
	border-radius: 17px;
	left: 1.05rem;
	top: 5.7rem;
}
.sisth_progress {
	left: 1.05rem;
	top: 5.7rem;
	position: absolute;
	width: 3rem;
	height: 0.34rem;
	background: linear-gradient(0deg, #ff92f3 0%, #bf68ff 100%);
	border-radius: 17px;
}
.sisth_progress_value {
	font-size: 0.24rem;
	color: #d855fd;
	position: absolute;
	right: 3.9rem;
	top: 6.1rem;
	font-weight: bolder;
}
.sisth_progress_total_value {
	font-size: 0.24rem;
	color: #675096;
	position: absolute;
	left: 3.7rem;
	top: 6.1rem;
	font-weight: bolder;
}
.scan_btn {
	width: 3.08rem;
	height: 1.24rem;
	position: absolute;
	left: 2.21rem;
	top: 6.6rem;
}
.scan_btn_img{
	width: 3.08rem;
	height: 1.24rem;
}
.little_c_scan {
	width: 2.44rem;
	height: 2.79rem;
	position: absolute;
	left: 2.53rem;
	top: 11.3rem;
}
</style>
