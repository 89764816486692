import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vconsole from 'vconsole'
import axios from 'axios'
import utils from './utils/urlGet.js'; //获取url参数
import wechatUtils from './utils/wechatUtil.js' //获取微信工具
// import './common/font.css';

window.addEventListener('popstate', function() {
 history.pushState(null, null, document.URL)
})

//注册全局方法
Vue.prototype.$axios = axios
Vue.prototype.$utils = utils;
Vue.prototype.$wechatUtils = wechatUtils;
Vue.config.productionTip = false

// Vue.prototype.$vConsole = new Vconsole();
new Vue({
	router,
	store,
	render: function(h) {
		return h(App)
	}
}).$mount('#app')
